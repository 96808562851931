<style scoped lang="less">
.top-item-row {
  margin: 0px 15px;
  display: flex;
  justify-content: space-between;
}

.item-row {
  margin: 0.5rem 0.5rem 1rem 1rem;
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.item-row > span {
  margin: 0 15px;
}

.input_has_unit {
  width: 200px;
  margin: 5px 0;
}
.input_item {
  margin: 5px 0;
  width: 200px;
}

.dialogContent::-webkit-scrollbar {
  width: 12px;
}

.dialogContent::-webkit-scrollbar-track {
  border-radius: 10px;
}

.dialogContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.dialogContent::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.dialogContent {
  flex: 1;
  overflow: auto;
}
</style>

<template>
  <CmDialogBorder
    size="horizontal"
    :zIndex="zIndex"
    :title="form.uuid ? '编辑不良反应' : '新增不良反应'"
    v-show="isShow"
    @close="Close"
  >
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialogContent">
      <div class="top-item-row">
        <div class="item-row">
          <div class="input-form-item-label form-item-label">名称</div>
          <template v-if="isShow">
            <el-select
              class="input_item"
              v-model="form.bad"
              multiple
              filterable
              allow-create
              default-first-option
            >
              <el-option
                v-for="(item, index) in optionList"
                :key="index"
                :label="item?.name"
                :value="item?.name"
              ></el-option>
            </el-select>
          </template>
        </div>
        <div class="item-row">
          <template>
            <div class="input-form-item-label form-item-label">级别</div>
            <cqt-select
              class="input_item"
              activeUrl="/v1/webconsole/comboData/get/SOLUTION_bad_level"
              :value.sync="form.level"
            />
          </template>
        </div>
        <div class="item-row">
          <template>
            <div class="input-form-item-label form-item-label">出现日期</div>
            <date-panel
              class="input_item"
              ref="datePanel"
              v-model="form.startDate"
              type="date"
              align="center"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
            ></date-panel>
          </template>
        </div>
      </div>
      <div class="top-item-row">
        <div class="item-row">
          <template>
            <div class="input-form-item-label">转归日期</div>
            <date-panel
              class="input_item"
              ref="datePanel"
              v-model="form.mendDate"
              type="date"
              align="center"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
            ></date-panel>
          </template>
        </div>
        <div class="item-row">
          <div class="input-form-item-label">转归结果</div>
          <cqt-input
            style="margin: 5px 0"
            :isReadonly="isReadonly"
            placeholder="请输入"
            type="textarea"
            v-model="form.mendResult"
            size="large"
          />
        </div>
        <div class="item-row"></div>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../../../components/CmDialogBorder.vue'
import deepClone from '../../../../../../plugins/clone'
// cqt
import CqtInput from '../../../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../../../components/cqt/CqtRadio.vue'

import DatePanel from '../../../../../Menu/components/date-picker/index'

export default {
  name: 'AddFollowDialog',
  components: {
    CmDialogBorder,
    CqtInput,
    CqtSelect,
    CqtRadio,
    DatePanel
  },

  props: {
    isReadonly: {
      type: Boolean,
      default: false
    },
    typeName: {
      type: String,
      default: '新增'
    }
  },

  data() {
    return {
      zIndex: 9,
      isShow: false,
      currentTreatment: '',
      form: {
        bad: null, //不良反应名称
        level: null, //不良反应级别
        startDate: null, //出现日期
        mendDate: null, //转归日期
        mendResult: null //转归结果
      },
      optionList: []
    }
  },
  mounted() {
    this.getBadName()
  },
  methods: {
    getBadName() {
      this.$api.post('/v1/webconsole/study/pubAdverseEventsList').then(({ data }) => {
        if (data.status === 200) {
          this.optionList = data.data || []
        }
      })
    },
    Show(val) {
      this.form = {}
      if (val) {
        this.form = { ...deepClone(val), bad: deepClone(val).bad?.split('|') }
      }

      this.isShow = true
    },
    Close() {
      this.isShow = false
    },

    async Save() {
      if (!this.form.bad || this.form.bad?.length === 0) {
        return this.$message.warning('请输入不良反应名称')
      }
      if (!this.form.level) {
        return this.$message.warning('请选择不良反应级别')
      }
      if (!this.form.startDate) {
        return this.$message.warning('请选择不良反应出现日期')
      }

      const params = { ...this.form, bad: this.form.bad?.join('|') }

      this.$emit('save', params)
      this.Close()
    }
  }
}
</script>
