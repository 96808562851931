<template>
  <div class="container">
    <div class="crt-content" style="background: #fff">
      <h3 style="margin-left: 20px; display: flex; align-items: center">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-circle-plus-outline"
          class="commonBtn"
          @click="add"
        >
          新增
        </el-button>
        <el-button
          style="margin-left"
          type="warning"
          size="mini"
          icon="el-icon-edit"
          class="commonBtn"
          @click="edit"
        >
          编辑
        </el-button>
      </h3>
    </div>
    <div class="content-box">
      <div class="component">
        <el-table
          class="inner-table"
          :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
          highlight-current-row
          :header-cell-style="{ padding: 0 }"
          min-height="200px"
          @row-click="_rowClick"
          @row-dblclick="_doubleModify"
          style="width: 100%"
          border
        >
          <el-table-column prop="bad" label="名称" align="center"></el-table-column>
          <el-table-column width="130" prop="level" label="级别" align="center"></el-table-column>
          <el-table-column
            width="130"
            prop="startDate"
            label="出现日期"
            align="center"
          ></el-table-column>
          <el-table-column
            width="130"
            prop="mendDate"
            label="转归日期"
            align="center"
          ></el-table-column>
          <el-table-column prop="mendResult" label="转归结果" align="center"></el-table-column>
        </el-table>
        <div class="block" style="margin-top: 15px">
          <el-pagination
            align="center"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[1, 5, 10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length"
          ></el-pagination>
        </div>
      </div>
    </div>

    <AdverseEventsDialog ref="AdverseEventsDialog" @save="_dialogSave"></AdverseEventsDialog>
  </div>
</template>

<script>
import AdverseEventsDialog from './components/AdverseEventsDialog.vue'

export default {
  name: 'Medication',
  components: {
    AdverseEventsDialog
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isReadonly: true,
      loading: false,
      tableData: [],
      selectedRow: null,
      currentPage: 1, // 当前页码
      total: undefined, // 总条数
      pageSize: 20 // 每页的数据条数
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val?.id) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    initData() {
      this.$api.get(`/v1/webconsole/solution/bad/list/${this.info.id}`).then((res) => {
        if (res.data.status === 200) {
          this.tableData = res.data.data
        }
      })
    },
    add() {
      this.$refs.AdverseEventsDialog.Show()
    },
    _rowClick(row) {
      this.selectedRow = row
    },
    _doubleModify(row) {
      this.selectedRow = row
      this.$refs.AdverseEventsDialog.Show(this.selectedRow)
    },
    // 编辑
    edit() {
      if (!this.selectedRow) {
        return this.$message.warning('请选择一条不良反应')
      }
      this.$refs.AdverseEventsDialog.Show(this.selectedRow)
    },
    _dialogSave(val) {
      if (!val || !this.info) return
      this.loading = true
      const data = { ...val }
      this.$api.post(`/v1/webconsole/solution/bad/save/${this.info.id}`, data).then((res) => {
        if (res.data.status === 200) {
          this.loading = false
          this.initData()
          this.selectedRow = null
          this.$message.success('保存成功！')
        }
      })
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  .component {
    padding: 0px 10px;
    .list {
      display: flex;
      flex-wrap: wrap;
    }
    .content-title {
      width: 99%;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      padding: 15px 0;
      border-bottom: 1px solid rgb(223, 220, 220);
      float: left;
      div {
        border-left: 4px solid rgb(1, 72, 226);
        padding-left: 5px;
      }
    }

    .deep {
      width: 100%;
      float: left;
    }
    .content-basic {
      width: 99%;
      min-height: 30px;
      float: left;
      padding: 10px 0;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .basic-div {
      min-width: 30%;
      min-height: 30px;
      float: left;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      font-size: 16px;
      /deep/.editStyle {
        .el-input__inner {
          background-color: #ffffcc;
        }
      }
      /deep/.textareaEditStyle {
        .el-textarea__inner {
          background-color: #ffffcc;
        }
      }
      /deep/ .radioEditStyle {
        .is-checked {
          background-color: #ffffcc;
        }
      }
    }
    .basic-child {
      float: none;
    }
    /deep/.el-radio__input {
      display: none !important;
    }
    .select-title {
      min-width: 150px;
      white-space: nowrap;
    }
    /deep/.radio {
      word-break: normal;
      .el-radio__input {
        display: none !important;
      }
      .el-checkbox-group,
      .el-radio-group {
        margin: 0;
      }
      .el-input__inner {
        appearance: none;
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 30px;
        line-height: 30px;
        outline: none;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
      }
      .is-bordered {
        padding: 12px 20px 0 10px;
        border-radius: 4px;
        box-sizing: border-box;
        height: 30px;
        line-height: 5px;
      }
      // 日期选择框
      .el-date-editor--date {
        .el-input__inner {
          padding: 0 30px;
        }
      }
      .el-checkbox {
        padding-top: 5px;
      }
      // 只读
      .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
        border-color: #53565c;
      }
      .el-input.is-disabled .el-input__inner {
        background-color: #f5f7fa;
        border-color: #e4e7ed;
        color: #000;
        cursor: not-allowed;
      }
      .el-input .el-input__inner {
        color: #000;
      }
      .el-textarea .el-textarea__inner {
        color: #000;
      }
      .is-disabled .el-textarea__inner::placeholder {
        color: #000;
      }
      /* 谷歌 */
      .is-disabled .el-textarea__inner::-webkit-input-placeholder {
        color: #000;
      }
      /* 火狐 */
      .is-disabled .el-textarea__inner:-moz-placeholder {
        color: #000;
      }
      /*ie*/
      .is-disabled .el-textarea__inner:-ms-input-placeholder {
        color: #000;
      }

      .is-disabled .el-input__inner::placeholder {
        color: #000;
      }
      /* 谷歌 */
      .is-disabled .el-input__inner::-webkit-input-placeholder {
        color: #000;
      }
      /* 火狐 */
      .is-disabled .el-input__inner:-moz-placeholder {
        color: #000;
      }
      /*ie*/
      .is-disabled .el-input__inner:-ms-input-placeholder {
        color: #000;
      }
      .el-checkbox__input.is-disabled + span.el-checkbox__label {
        color: #53565c;
      }
      .el-radio.is-bordered.is-disabled.is-checked {
        border: 2px solid #53565c;
        .el-radio__label {
          color: #53565c;
        }
      }
      .el-radio__input.is-disabled + span.el-radio__label {
        color: #53565c;
      }
      .el-textarea.is-disabled {
        .el-textarea__inner {
          color: #000;
        }
      }
    }
    .div {
      width: 100%;
    }
    /deep/.el-checkbox-group,
    .el-radio-group {
      margin: 0;
    }
    .radio-textarea {
      width: 800px;
    }
    div {
      margin-right: 10px;
    }
    /deep/.el-input__inner {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 30px;
      line-height: 30px;
      outline: none;
      padding: 0 15px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
    }
    /deep/.is-bordered {
      padding: 12px 20px 0 10px;
      border-radius: 4px;
      box-sizing: border-box;
      height: 30px;
      line-height: 5px;
    }
  }

  .input_has_unit {
    margin-left: 0px !important;
  }
}
</style>
